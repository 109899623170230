import { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  SwipeableDrawer,
  ListItemButton,
  ListItemText,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  Skeleton,
} from '@mui/material';
import { IAIhistory } from '../../../../@types/ai';
import { useLocales } from '../../../../locales';
import { useTheme, alpha } from '@mui/material/styles';
import Iconify from '../../../../components/iconify';
import AIHistoryButton from './AIHistoryButton';
import useResponsive from '../../../../hooks/useResponsive';
import { keyframes } from '@mui/system';
import Latex from 'react-latex-next';

type ChatHistoryProps = {
  fetchAIChatHistory: (chatid: number) => void;
  handleGetHistory: () => void;
  aiChatList: IAIhistory[];
};

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

export default function ChatHistory({
  aiChatList,
  handleGetHistory,
  fetchAIChatHistory,
}: ChatHistoryProps) {
  const { translate } = useLocales();
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isMobile = useResponsive('down', 'sm');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchChatMessages = (chatId: number) => {
    setOpen(false);
    console.log('CHAT ID', chatId);
    fetchAIChatHistory(chatId);
  };

  const filteredChats = aiChatList.filter((chat) =>
    chat.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {open ? (
        <SwipeableDrawer
          anchor={isMobile ? 'bottom' : 'right'}
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          swipeAreaWidth={isMobile ? 56 : 0}
          disableSwipeToOpen={!isMobile}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              height: isMobile ? 'auto' : '100%',
              maxHeight: isMobile ? '90%' : '100%',
              borderTopLeftRadius: isMobile ? theme.shape.borderRadius : 0,
              borderTopRightRadius: isMobile ? theme.shape.borderRadius : 0,
              backgroundImage: `linear-gradient(to bottom, ${alpha(
                theme.palette.primary.light,
                0.05
              )}, ${alpha(theme.palette.background.default, 1)})`,
              transition: theme.transitions.create(['height', 'max-height'], {
                duration: theme.transitions.duration.standard,
              }),
            },
          }}
        >
          <Box
            sx={{
              width: isMobile ? 'auto' : 400,
              padding: theme.spacing(3),
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
            >
              <Typography variant="h6" component="h2">
                {String(translate('ai.chat.history'))}
              </Typography>
              <IconButton onClick={handleClose} edge="end" aria-label="close">
                <Iconify icon="ph:x" />
              </IconButton>
            </Box>

            <TextField
              fullWidth
              variant="outlined"
              name="historySearchField"
              placeholder={String(translate('ai.chat.historySearchPlaceholder'))}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.background.paper,
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="ph:magnifying-glass" sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
              }}
            />

            <List
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                '-webkit-overflow-scrolling': 'touch',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.3),
                  },
                },
              }}
            >
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="100%"
                      height={60}
                      sx={{ mb: 1, borderRadius: theme.shape.borderRadius }}
                    />
                  ))
                : filteredChats.map((chat, index) => (
                    <ListItem
                      disableGutters
                      key={index}
                      onClick={() => handleFetchChatMessages(chat.id)}
                      sx={{
                        mb: 1,
                        borderRadius: theme.shape.borderRadius,
                        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                        overflow: 'hidden',
                        p: 0,
                        position: 'relative',
                        boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
                        transition: theme.transitions.create(['box-shadow'], {
                          duration: theme.transitions.duration.shortest,
                        }),
                        '&:hover': {
                          boxShadow: `0 4px 8px ${alpha(theme.palette.common.black, 0.1)}`,
                        },
                      }}
                    >
                      {index === 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            fontSize: '0.75rem',
                            padding: '2px 6px',
                            borderBottomLeftRadius: theme.shape.borderRadius,
                            boxShadow: `0 1px 2px ${alpha(theme.palette.common.black, 0.2)}`,
                          }}
                        >
                          {String(translate('ai.chat.newest'))}
                        </Box>
                      )}
                      <ListItemButton
                        onClick={() => handleFetchChatMessages(chat.id)}
                        sx={{
                          width: '100%',
                          py: 1.5,
                          px: 2,
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                          },
                          transition: theme.transitions.create(['background-color'], {
                            duration: theme.transitions.duration.shortest,
                          }),
                        }}
                      >
                        <Iconify
                          icon="ph:chat-circle-text-fill"
                          sx={{
                            mr: 2,
                            color: theme.palette.primary.main,
                            fontSize: 24,
                            animation:
                              index === 0 ? `${pulseAnimation} 2s infinite ease-in-out` : 'none',
                          }}
                        />
                        <ListItemText
                          primary={<Latex>{chat.title}</Latex> || 'Untitled Chat'}
                          primaryTypographyProps={{
                            noWrap: true,
                            fontWeight: index === 0 ? 'bold' : 'normal',
                          }}
                          // secondary={new Date(chat.created_at).toLocaleDateString()}
                          secondaryTypographyProps={{
                            variant: 'caption',
                            color: 'text.secondary',
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </Box>
        </SwipeableDrawer>
      ) : (
        <AIHistoryButton handleGetHistory={handleGetHistory} setOpenHistory={setOpen} />
      )}
    </>
  );
}
