import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import LogoIcon from '../../../components/logoicon';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'lg');

  const { user } = useAuthContext();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 10,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        {pathname !== '/ai' ? (
          <Logo redirectPath={user?.freemium_signup ? '/ai' : '/dashboard'} />
        ) : (
          <LogoIcon redirectPath={user?.freemium_signup ? '/ai' : '/dashboard'} />
        )}

        {/* <NavAccount /> */}
      </Stack>

      <NavSectionVertical data={navConfig(user, translate)} />

      <Box sx={{ flexGrow: 1 }} />

      <NavDocs />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: pathname !== '/ai' ? NAV.W_DASHBOARD : 100 },
      }}
    >
      {isDesktop ? (
        <Drawer
          open={pathname !== '/ai' ? true : false}
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: pathname !== '/ai' ? NAV.W_DASHBOARD : 100,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
